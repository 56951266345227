.login-form {
  padding: 48px 59px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .login-form.form-submitting .form-fields-container {
    opacity: 0.5; }
  .login-form .form-title {
    font-family: 'Px Grotesk';
    font-size: 26px;
    line-height: 24px; }
  .login-form .form-fields-container {
    opacity: 1;
    -webkit-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .login-form .form-fields-container .form-fields {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
    .login-form .form-fields-container .form-fields {
      padding-top: 20px;
      width: 250px; }
  .login-form .iris-button-container {
    padding-top: 40px;
    width: 250px; }
    .login-form .iris-button-container .login-button {
      width: 100% !important;
      height: 32px !important; }
      .login-form .iris-button-container .login-button .loading-gif {
        margin-top: -1px !important; }
  .login-form .forgot-password-container, .login-form .signup-container {
    margin-top: 25px; }
    .login-form .forgot-password-container .forgot-password-cta, .login-form .forgot-password-container .signup-text, .login-form .forgot-password-container .signup-text-cta, .login-form .signup-container .forgot-password-cta, .login-form .signup-container .signup-text, .login-form .signup-container .signup-text-cta {
      font-size: 13px;
      color: #757575;
      border-color: #757575; }

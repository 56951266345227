.menu-options-container {
  position: relative;
  z-index: 1;
  width: 184px;
  background: white;
  border-top: 2px solid #534DC9;
  -webkit-box-shadow: 0px 0px 4px 1px #CBCBCB;
          box-shadow: 0px 0px 4px 1px #CBCBCB;
  padding: 8px 0px; }
  .menu-options-container .label {
    padding: 8px 16px;
    font-size: 13px;
    font-family: 'Px Grotesk Light';
    cursor: pointer; }

.team-page {
  display: -ms-flexbox;
  display: flex; }
  .team-page .team-view-container {
    width: 100%;
    margin: 40px 65px; }
    .team-page .team-view-container .team-view-header-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.licenses-grid-container .grid-container {
  margin-bottom: 93px; }
  .licenses-grid-container .grid-container .grid-row .cell-container:first-child {
    width: 40%; }
  .licenses-grid-container .grid-container .grid-row .cell-container:nth-child(2) {
    width: 200px; }
  .licenses-grid-container .grid-container .licenses-section-container {
    margin-top: 16px; }
    .licenses-grid-container .grid-container .licenses-section-container .header {
      text-transform: uppercase;
      color: #534DC9;
      font-size: 13px;
      line-height: 27px;
      letter-spacing: 2px; }

.navbar {
  height: 58px; }

.app-inner-content {
  margin-top: 58px;
  margin-left: 250px;
  position: relative;
  min-height: calc(100vh - 58px);
  width: 100%;
  min-width: 950px; }

.login-page {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: calc(100vh - 58px);
  padding-top: 58px; }
  .login-page .confirmation-message-container {
    margin-bottom: 30px; }
    .login-page .confirmation-message-container .error {
      color: #f44336;
      max-width: 440px;
      text-align: center; }
  .login-page .content-container {
    width: 452px; }

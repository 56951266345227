.trial-start-view-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .trial-start-view-container .image-container img {
    height: 250px; }
  .trial-start-view-container .error-container {
    margin-top: 20px; }
    .trial-start-view-container .error-container .error {
      font-size: 12px;
      color: #f44336; }

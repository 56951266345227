.section-container.prospect-section-div {
  min-width: 370px; }

.section-container.scope-section-div {
  max-width: 250px; }
  .section-container.scope-section-div .description-container {
    min-height: 72px; }
  .section-container.scope-section-div.library-div {
    max-width: 250px; }
    .section-container.scope-section-div.library-div .title-container img {
      width: 188px; }
    .section-container.scope-section-div.library-div .title-container p {
      display: none; }
    .section-container.scope-section-div.library-div .description-container {
      min-height: 66px; }

.section-container .title-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 20px;
  height: 22px; }
  .section-container .title-container .icon {
    margin-right: 20px; }
  .section-container .title-container p {
    margin: 0;
    font-size: 19px; }

.section-container .description-container {
  margin-bottom: 30px; }
  .section-container .description-container p {
    font-family: 'Px Grotesk Light';
    line-height: 24px; }

.section-container .cta-container a {
  color: #9CABF0; }

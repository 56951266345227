.reset-password-with-token-form {
  padding: 48px 59px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .reset-password-with-token-form.form-submitting .form-fields-container {
    opacity: 0.5; }
  .reset-password-with-token-form .form-title {
    font-family: 'Px Grotesk';
    font-size: 26px;
    line-height: 24px; }
  .reset-password-with-token-form .form-fields-container {
    opacity: 1;
    -webkit-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .reset-password-with-token-form .form-fields-container .form-fields {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      padding-top: 20px;
      width: 250px; }
  .reset-password-with-token-form .iris-button-container {
    padding-top: 60px;
    width: 250px; }
    .reset-password-with-token-form .iris-button-container .reset-password-button {
      width: 100% !important;
      height: 32px !important; }
      .reset-password-with-token-form .iris-button-container .reset-password-button .loading-gif {
        margin-top: -1px !important; }

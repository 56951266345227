.iris-transition-container {
  position: relative; }
  .iris-transition-container .initial-component, .iris-transition-container .final-component {
    -webkit-transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .iris-transition-container .final-component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.admin-mode {
  position: absolute;
  z-index: 3;
  width: 100%;
  margin-top: 58px;
  padding: 8px 0px 7px 0px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  background: #FF7752;
  color: white;
  position: fixed; }
  .admin-mode p {
    font-size: 14px; }
  .admin-mode i {
    font-size: 15px;
    position: absolute;
    right: 0;
    padding-right: 7px;
    cursor: pointer; }
  .admin-mode .header {
    margin-bottom: 5px; }

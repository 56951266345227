.authenticated-navbar-component {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end; }
  .authenticated-navbar-component .component-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .authenticated-navbar-component .component-container .name-container {
      padding: 0px 8px; }
      .authenticated-navbar-component .component-container .name-container p {
        font-family: 'Px Grotesk Bold';
        font-size: 16px; }

.null-licenses-view-container {
  margin-top: 80px; }
  .null-licenses-view-container p {
    text-align: center;
    font-size: 13px;
    font-family: 'Px Grotesk Light';
    line-height: 19px; }
  .null-licenses-view-container a {
    color: #534DC9;
    font-family: 'Px Grotesk Bold';
    border: none; }

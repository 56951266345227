.subscription-card-header-container {
  padding: 22px; }
  .subscription-card-header-container .plan-name-container {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
    .subscription-card-header-container .plan-name-container .plan-name {
      font-size: 26px;
      font-family: 'Px Grotesk Bold';
      padding-bottom: 5px; }
  .subscription-card-header-container .cell-container {
    color: #757575; }
  .subscription-card-header-container .status-container {
    color: #534DC9; }

.navbar {
  height: 58px; }

.app-inner-content {
  margin-top: 58px;
  margin-left: 250px;
  position: relative;
  min-height: calc(100vh - 58px);
  width: 100%;
  min-width: 950px; }

.select-org-page {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: calc(100vh - 58px);
  padding-top: 58px; }
  .select-org-page .select-org-card-container {
    width: 450px; }
    .select-org-page .select-org-card-container .select-org-inner-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-direction: column;
          flex-direction: column;
      position: relative;
      padding: 48px 59px; }
      .select-org-page .select-org-card-container .select-org-inner-container .text-container {
        opacity: 0;
        pointer-events: none; }
        .select-org-page .select-org-card-container .select-org-inner-container .text-container .title-container {
          padding-bottom: 30px;
          text-align: center; }
          .select-org-page .select-org-card-container .select-org-inner-container .text-container .title-container .title {
            font-size: 26px;
            font-family: 'Px Grotesk Bold'; }
        .select-org-page .select-org-card-container .select-org-inner-container .text-container .orgs-list-container .org-list-item {
          display: -ms-flexbox;
          display: flex;
          margin: 10px 0px;
          padding: 15px 0px;
          -ms-flex-pack: center;
              justify-content: center;
          cursor: pointer; }
          .select-org-page .select-org-card-container .select-org-inner-container .text-container .orgs-list-container .org-list-item .org-title {
            -webkit-transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            -o-transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            font-size: 19px;
            color: #757575; }
          .select-org-page .select-org-card-container .select-org-inner-container .text-container .orgs-list-container .org-list-item .material-icons {
            position: absolute;
            opacity: 0;
            padding-left: 0px;
            margin-top: -2px;
            -webkit-transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            -o-transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            color: #534DC9; }
          .select-org-page .select-org-card-container .select-org-inner-container .text-container .orgs-list-container .org-list-item:hover .org-title {
            color: black; }
          .select-org-page .select-org-card-container .select-org-inner-container .text-container .orgs-list-container .org-list-item:hover .material-icons {
            opacity: 1 !important;
            padding-left: 50px; }
      .select-org-page .select-org-card-container .select-org-inner-container .loader-container {
        position: absolute;
        color: #534DC9; }

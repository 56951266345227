.logo-upload-container {
  margin-top: 76px; }
  .logo-upload-container .label-container {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px; }
    .logo-upload-container .label-container .label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54); }
    .logo-upload-container .label-container .tooltip-container {
      margin-left: 6px; }
      .logo-upload-container .label-container .tooltip-container a {
        color: #828282;
        border: none; }
      .logo-upload-container .label-container .tooltip-container i {
        font-size: 16px; }
      .logo-upload-container .label-container .tooltip-container .iris-tooltip {
        width: 220px; }
  .logo-upload-container .body-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
    .logo-upload-container .body-container .logo-container {
      height: 50px;
      display: -ms-flexbox;
      display: flex; }
      .logo-upload-container .body-container .logo-container .logo-viewer {
        position: relative;
        min-width: 200px; }
        .logo-upload-container .body-container .logo-container .logo-viewer .logo-overlay {
          position: absolute;
          width: 100%;
          height: 50px;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center;
          opacity: 0;
          -webkit-transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          -o-transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .logo-upload-container .body-container .logo-container .logo-viewer .logo-overlay.uploading {
            opacity: 1; }
          .logo-upload-container .body-container .logo-container .logo-viewer .logo-overlay .loading-gif {
            position: absolute;
            z-index: 1; }
          .logo-upload-container .body-container .logo-container .logo-viewer .logo-overlay .sheet {
            opacity: 0.8;
            background: white;
            width: 100%;
            height: 50px; }
        .logo-upload-container .body-container .logo-container .logo-viewer .logo-image-container {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center;
          height: 50px;
          background: black; }
          .logo-upload-container .body-container .logo-container .logo-viewer .logo-image-container img {
            max-height: 36px;
            padding: 7px 20px; }
        .logo-upload-container .body-container .logo-container .logo-viewer .logo-placeholder {
          width: 200px;
          height: 50px;
          background: black;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center; }
          .logo-upload-container .body-container .logo-container .logo-viewer .logo-placeholder .suggested-dimensions {
            font-size: 11px;
            color: white; }
      .logo-upload-container .body-container .logo-container .directions {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        margin-left: 30px;
        max-width: 190px;
        font-size: 13px;
        color: #757575; }
    .logo-upload-container .body-container .cta-container {
      margin-top: 8px; }
      .logo-upload-container .body-container .cta-container .error {
        max-width: 460px;
        font-size: 13px;
        color: #f44336; }
      .logo-upload-container .body-container .cta-container a {
        font-size: 13px;
        color: #534DC9;
        border-bottom: none; }

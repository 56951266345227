.trial-view-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  margin-top: 120px; }
  .trial-view-container .description-container {
    margin: 40px 0px; }
    .trial-view-container .description-container p {
      color: #757575; }
  .trial-view-container .iris-cta-button {
    padding: 16px; }

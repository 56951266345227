.banner-container {
  background: #534DC9;
  color: white;
  text-align: center;
  padding: 16px 0px;
  position: relative; }
  .banner-container .dismiss-container {
    position: absolute;
    top: 13px;
    right: 10px; }
    .banner-container .dismiss-container .dismiss {
      cursor: pointer; }

.navbar {
  height: 58px; }

.app-inner-content {
  margin-top: 58px;
  margin-left: 250px;
  position: relative;
  min-height: calc(100vh - 58px);
  width: 100%;
  min-width: 950px; }

.notification-container {
  position: fixed;
  z-index: 1;
  width: 100%;
  margin-top: 58px; }

.iris-modal {
  z-index: 1050;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  outline: none;
  -webkit-transform: translate3d(-50%, -50%, 0px);
          transform: translate3d(-50%, -50%, 0px);
  background-color: white;
  min-width: 450px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  border-top: 6px solid #534DC9;
  padding: 48px;
  -webkit-box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.4);
  max-height: 80vh; }
  .iris-modal .close-icon {
    color: #616161;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 16px;
    cursor: pointer; }
  .iris-modal .modal-title-container {
    margin-bottom: 32px; }
    .iris-modal .modal-title-container .modal-title {
      font-family: 'Px Grotesk Bold';
      font-size: 16px;
      line-height: 24px; }
  .iris-modal a {
    color: #534DC9; }
  .iris-modal .modal-body-container .body-content {
    color: #757575;
    font-family: 'Px Grotesk';
    font-size: 16px;
    line-height: 24px; }
  .iris-modal .iris-button-container {
    margin-top: 32px; }
    .iris-modal .iris-button-container button {
      padding: 8px 16px !important; }

.iris-modal-overlay {
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important; }
  .iris-modal-overlay.no-overlay {
    background-color: transparent !important;
    left: 300px;
    bottom: 300px;
    top: unset;
    right: unset;
    overflow: visible; }

.ReactModalPortal > div {
  opacity: 0; }

.ReactModalPortal .ReactModal__Overlay {
  -webkit-transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1); }
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1; }
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0; }

.manage-workstation-modal.copy-workstation-key-modal {
  width: 470px; }

.manage-workstation-modal.release-workstation-key-modal {
  width: 354px; }

.manage-workstation-modal .modal-body-container {
  padding: 0px 20px 10px 20px; }
  .manage-workstation-modal .modal-body-container .workstation-key-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    .manage-workstation-modal .modal-body-container .workstation-key-container .workstation-key {
      color: #534DC9;
      margin: 20px 4px 0px 4px;
      font-family: 'Px Grotesk Bold'; }

.manage-workstation-modal .iris-button-container button {
  width: 230px; }

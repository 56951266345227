.nested-menu-items {
  padding-left: 85px;
  -webkit-transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .nested-menu-items .nested-link .nested-menu-item {
    list-style-type: none;
    color: #E0E0E0;
    margin-top: 20px;
    -webkit-transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .nested-menu-items .nested-link .nested-menu-item:hover {
      color: #bababa; }
  .nested-menu-items .nested-link.selected-nested-link .nested-menu-item {
    color: #9CABF0; }
  .nested-menu-items.collapsed-menu-items {
    pointer-events: none;
    -webkit-transform: translate(0px, -10px);
        -ms-transform: translate(0px, -10px);
            transform: translate(0px, -10px);
    opacity: 0; }
  .nested-menu-items.expanded-menu-items {
    -webkit-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    opacity: 1; }

.company-settings-form-container .company-settings-form .form-fields {
  max-width: 690px;
  display: -ms-flexbox;
  display: flex; }
  .company-settings-form-container .company-settings-form .form-fields .form-input-field {
    margin-top: 46px;
    margin-right: 24px;
    width: 316px; }

.company-settings-form-container .company-settings-form .billing-address-fields .form-fields-title {
  display: none; }

.company-settings-form-container .company-settings-form .billing-address-fields .form-input-field {
  width: 656px; }

.company-settings-form-container .company-settings-form .billing-address-fields .field-country, .company-settings-form-container .company-settings-form .billing-address-fields .field-state, .company-settings-form-container .company-settings-form .billing-address-fields .field-city, .company-settings-form-container .company-settings-form .billing-address-fields .field-zipcode {
  text-align: left;
  margin-top: 46px;
  margin-right: 24px;
  width: 316px; }

.company-settings-form-container .company-settings-form .iris-button-container {
  margin: 16px 60px; }
  .company-settings-form-container .company-settings-form .iris-button-container .iris-cta-button {
    background: #9CABF0; }
    .company-settings-form-container .company-settings-form .iris-button-container .iris-cta-button.activated {
      background: #534DC9; }

.company-settings-form-container .company-settings-footer {
  margin-left: -65px; }

.iris-tooltip-container .iris-tooltip {
  margin-left: 10px !important;
  background: white !important;
  color: black !important;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  font-size: 12px;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3); }
  .iris-tooltip-container .iris-tooltip.show {
    visibility: visible;
    opacity: 1 !important; }

.iris-tooltip-container .iris-tooltip.place-top::after,
.iris-tooltip-container .iris-tooltip.place-bottom::after {
  opacity: 0 !important; }

.trial-expired-view-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .trial-expired-view-container .image-container img {
    width: 282px; }
  .trial-expired-view-container .title-container {
    margin-top: 33px; }
    .trial-expired-view-container .title-container p {
      font-size: 26px;
      font-family: 'Px Grotesk Bold'; }
  .trial-expired-view-container .description-container {
    width: 400px;
    margin: 40px 0px;
    text-align: center;
    line-height: 24px; }

.primary-contact-form.form-submitting .form-fields-container {
  opacity: 0.5; }

.primary-contact-form .form-fields-container {
  opacity: 1;
  -webkit-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1); }

.primary-contact-form .form-fields {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .primary-contact-form .form-fields .form-input-field {
    margin-bottom: 20px; }

.primary-contact-form .error-container {
  margin-top: 20px; }
  .primary-contact-form .error-container .error {
    font-size: 12px;
    color: #f44336; }

.grid-row {
  position: relative;
  padding: 22px 0px;
  border-bottom: 1px solid #CBCBCB; }
  .grid-row p {
    font-size: 16px;
    color: black; }
    .grid-row p.term-start, .grid-row p.term-end {
      color: #757575; }
    .grid-row p.subscription-status {
      color: #534DC9; }
  .grid-row.inactive p {
    color: #CBCBCB !important; }

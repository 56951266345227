.navbar {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 14px 24px;
  background: white;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5); }
  .navbar .iris-logo-container {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-width: 226px;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .navbar .iris-logo-container a {
      border: none; }
    .navbar .iris-logo-container .iris-logo {
      height: 36px; }
  .navbar .org-name-container {
    width: 100%;
    border-left: 1px solid #979797;
    padding-left: 65px; }
    .navbar .org-name-container .org-name {
      font-family: 'Px Grotesk Bold';
      font-size: 24px;
      color: #3B3B3B; }

.download-prospect-view {
  text-align: center;
  padding: 48px 59px; }
  .download-prospect-view .download-container .title-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    .download-prospect-view .download-container .title-container .title {
      width: 280px;
      font-family: 'Px Grotesk Bold';
      font-size: 22px;
      line-height: 31px; }
  .download-prospect-view .download-container .button-container {
    padding-top: 22px; }
    .download-prospect-view .download-container .button-container .download-prospect {
      padding: 16px 18px; }
  .download-prospect-view .or-container .divisive-or {
    text-transform: uppercase;
    color: #757575;
    padding: 45px 0px; }
  .download-prospect-view .manage-account-settings .button-container {
    padding-top: 22px; }
    .download-prospect-view .manage-account-settings .button-container .login {
      -webkit-box-shadow: none;
              box-shadow: none;
      border: 1px solid #534DC9; }

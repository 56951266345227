.subscription-expanded-body-container {
  padding: 22px; }
  .subscription-expanded-body-container .expanded-component-container {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
    .subscription-expanded-body-container .expanded-component-container .cell-header-container {
      margin-bottom: 22px; }
      .subscription-expanded-body-container .expanded-component-container .cell-header-container .cell-header {
        font-family: 'Px Grotesk Bold'; }
    .subscription-expanded-body-container .expanded-component-container .cell-content-container {
      margin-bottom: 22px; }
    .subscription-expanded-body-container .expanded-component-container .cell-cta-container .cell-cta {
      color: #534DC9;
      font-size: 13px;
      border: none; }
    .subscription-expanded-body-container .expanded-component-container.billing-address-container, .subscription-expanded-body-container .expanded-component-container.billing-card-container {
      width: 420px !important;
      padding-right: 40px; }

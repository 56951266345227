.trial-progress-view-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .trial-progress-view-container .image-container img {
    height: 250px; }
  .trial-progress-view-container .description-container .days-left {
    padding: 0px 1px;
    font-family: 'Px Grotesk Bold';
    font-size: 22px;
    color: #534DC9; }
  .trial-progress-view-container .secondary-cta-container {
    margin-top: 32px; }
    .trial-progress-view-container .secondary-cta-container a {
      font-size: 13px;
      color: #534DC9;
      border-color: #534DC9; }

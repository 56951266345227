.iris-success-message-container {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .iris-success-message-container .success-icon {
    font-size: 48px;
    color: #727FE0; }
  .iris-success-message-container .success-message {
    padding-top: 20px; }

.reset-password-container {
  margin-top: 30px; }
  .reset-password-container .header {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54); }
  .reset-password-container .cta-text {
    margin-top: 14px;
    color: #534DC9;
    text-decoration: underline;
    cursor: pointer; }

.settings-form-container .settings-form .form-fields {
  max-width: 690px; }
  .settings-form-container .settings-form .form-fields .form-input-field {
    margin-top: 46px;
    margin-right: 24px;
    width: 316px; }
    .settings-form-container .settings-form .form-fields .form-input-field.iris-checkbox {
      width: inherit; }
      .settings-form-container .settings-form .form-fields .form-input-field.iris-checkbox span[class*="MuiTypography"] {
        color: #757575; }

.settings-form-container .settings-form .iris-button-container {
  margin: 16px 60px; }
  .settings-form-container .settings-form .iris-button-container .iris-cta-button {
    background: #9CABF0; }
    .settings-form-container .settings-form .iris-button-container .iris-cta-button.activated {
      background: #534DC9; }

.settings-form-container .settings-footer {
  margin-left: -65px; }

.grid-container .grid-row {
  display: -ms-flexbox;
  display: flex; }
  .grid-container .grid-row .cell-container {
    width: 150px;
    padding-right: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .grid-container .grid-row .cell-container:first-child {
      width: 40%; }
    .grid-container .grid-row .cell-container p {
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }

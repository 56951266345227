.subscriptions-grid-container .grid-container {
  margin-bottom: 93px; }
  .subscriptions-grid-container .grid-container .grid-header {
    margin: 0px 22px; }
    .subscriptions-grid-container .grid-container .grid-header.no-border {
      border-bottom: none !important; }
  .subscriptions-grid-container .grid-container .grid-row .cell-container:first-child {
    width: 40%; }
  .subscriptions-grid-container .grid-container .grid-row .cell-container {
    width: 200px;
    padding-right: 20px; }

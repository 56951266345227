.nav-dropdown {
  position: relative; }
  .nav-dropdown .arrow {
    padding-top: 2px;
    margin-left: -2px; }
  .nav-dropdown .menu-options-container {
    position: absolute;
    right: 0;
    top: 43px;
    width: 150px;
    border-top-width: 3px;
    -webkit-box-shadow: 0px 0px 2px 1px #CBCBCB;
            box-shadow: 0px 0px 2px 1px #CBCBCB; }

.grid-header {
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px !important;
  padding-bottom: 15px;
  border-bottom: 1px solid #757575 !important; }
  .grid-header p {
    color: #37328F !important;
    font-family: 'Px Grotesk Bold';
    letter-spacing: 2px;
    font-size: 13px !important;
    text-transform: uppercase; }

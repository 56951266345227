@font-face {
  font-family: 'Px Grotesk';
  src: url("./fonts/PxGrotesk-Regular.woff2"); }

@font-face {
  font-family: 'Px Grotesk Light';
  src: url("./fonts/PxGrotesk-Light.woff2"); }

@font-face {
  font-family: 'Px Grotesk Bold';
  src: url("./fonts/PxGrotesk-Bold.woff2"); }

/* BUTTONS */
.iris-button, .iris-cta-button, .iris-cta-button-inverse {
  border-radius: 0px;
  text-transform: uppercase;
  font-family: 'Px Grotesk';
  font-size: 13px;
  line-height: 1em;
  letter-spacing: 1px;
  padding: 8px 16px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  border-style: none;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  cursor: pointer; }
  .iris-button:focus, .iris-cta-button:focus, .iris-cta-button-inverse:focus {
    outline: none; }

.iris-cta-button {
  color: white;
  background: #534DC9; }
  .iris-cta-button:hover {
    background: #727FE0; }

.iris-cta-button-inverse {
  color: #534DC9;
  background: white;
  font-family: 'Px Grotesk Bold'; }
  .iris-cta-button-inverse:hover {
    color: #727FE0; }

.iris-form .form-input-field {
  margin-top: 20px; }

.iris-outline {
  border-top: 4px solid #534DC9;
  -webkit-box-shadow: 0px 0px 1px 1px #E0E0E0;
          box-shadow: 0px 0px 1px 1px #E0E0E0; }

.navbar {
  height: 58px; }

.app-inner-content {
  margin-top: 58px;
  margin-left: 250px;
  position: relative;
  min-height: calc(100vh - 58px);
  width: 100%;
  min-width: 950px; }

.iris-modal {
  z-index: 1050;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  outline: none;
  -webkit-transform: translate3d(-50%, -50%, 0px);
          transform: translate3d(-50%, -50%, 0px);
  background-color: white;
  min-width: 450px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  border-top: 6px solid #534DC9;
  padding: 48px;
  -webkit-box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.4);
  max-height: 80vh; }
  .iris-modal .close-icon {
    color: #616161;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 16px;
    cursor: pointer; }
  .iris-modal .modal-title-container {
    margin-bottom: 32px; }
    .iris-modal .modal-title-container .modal-title {
      font-family: 'Px Grotesk Bold';
      font-size: 16px;
      line-height: 24px; }
  .iris-modal a {
    color: #534DC9; }
  .iris-modal .modal-body-container .body-content {
    color: #757575;
    font-family: 'Px Grotesk';
    font-size: 16px;
    line-height: 24px; }
  .iris-modal .iris-button-container {
    margin-top: 32px; }
    .iris-modal .iris-button-container button {
      padding: 8px 16px !important; }

.iris-modal-overlay {
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important; }
  .iris-modal-overlay.no-overlay {
    background-color: transparent !important;
    left: 300px;
    bottom: 300px;
    top: unset;
    right: unset;
    overflow: visible; }

.ReactModalPortal > div {
  opacity: 0; }

.ReactModalPortal .ReactModal__Overlay {
  -webkit-transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1); }
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1; }
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0; }

::-moz-focus-inner {
  border: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: 'Px Grotesk'; }

p {
  margin: 0;
  font-family: 'Px Grotesk'; }

a {
  color: #9CABF0;
  text-decoration: none;
  border-bottom: 1px solid #9CABF0;
  cursor: pointer;
  outline: none; }
  a.no-underline {
    border: none; }

.emphasis {
  font-family: 'Px Grotesk Bold'; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

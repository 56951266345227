.billing-contact-footer {
  text-align: center;
  padding: 21px 0px; }
  .billing-contact-footer .cta-text {
    text-decoration: underline;
    color: black;
    border-bottom: none; }
    .billing-contact-footer .cta-text .emphasis {
      color: #534DC9;
      font-family: 'Px Grotesk Bold'; }

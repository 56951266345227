.org-onboard-form {
  padding: 48px 59px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .org-onboard-form.form-submitting .form-fields-container {
    opacity: 0.5; }
  .org-onboard-form .form-title {
    font-family: 'Px Grotesk';
    font-size: 26px;
    line-height: 24px; }
  .org-onboard-form .form-fields-container {
    opacity: 1;
    -webkit-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .org-onboard-form .form-fields-container .form-fields {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
    .org-onboard-form .form-fields-container .form-fields {
      padding-top: 20px; }
      .org-onboard-form .form-fields-container .form-fields .form-input-field {
        margin: 10px; }
    .org-onboard-form .form-fields-container .checkboxes-container {
      margin: 16px 10px 0px 10px; }
      .org-onboard-form .form-fields-container .checkboxes-container .iris-checkbox {
        margin-top: 0px; }
      .org-onboard-form .form-fields-container .checkboxes-container .terms-error {
        color: #f44336;
        font-size: 12px;
        position: absolute;
        margin-top: -6px; }
  .org-onboard-form .iris-button-container {
    padding-top: 20px;
    width: 250px; }
    .org-onboard-form .iris-button-container .signup-button {
      width: 100% !important;
      height: 32px !important; }
      .org-onboard-form .iris-button-container .signup-button .loading-gif {
        margin-top: -1px !important; }

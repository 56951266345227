.navbar {
  height: 58px; }

.app-inner-content {
  margin-top: 58px;
  margin-left: 250px;
  position: relative;
  min-height: calc(100vh - 58px);
  width: 100%;
  min-width: 950px; }

.sidebar {
  z-index: 1;
  position: fixed;
  margin-top: 58px;
  height: calc(100vh - 58px);
  min-width: 250px;
  float: left;
  background: black; }
  .sidebar .menu-items {
    padding-top: 30px;
    padding-left: 0px;
    list-style: none; }
  .sidebar .menu-link .menu-item-container {
    padding: 10px 0px; }
    .sidebar .menu-link .menu-item-container:hover .menu-item {
      padding-left: 35px; }
      .sidebar .menu-link .menu-item-container:hover .menu-item .main-tab .name-container {
        color: #bababa; }
    .sidebar .menu-link .menu-item-container .menu-item {
      padding: 4px 0px 4px 30px;
      font-size: 16px;
      border-left: 6px solid black;
      -webkit-transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      -o-transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }
      .sidebar .menu-link .menu-item-container .menu-item.selected-menu-item {
        border-color: #9CABF0;
        padding-left: 30px; }
        .sidebar .menu-link .menu-item-container .menu-item.selected-menu-item .main-tab .name-container {
          color: #9CABF0; }
      .sidebar .menu-link .menu-item-container .menu-item .main-tab {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        border: none; }
        .sidebar .menu-link .menu-item-container .menu-item .main-tab .icon-container {
          display: -ms-flexbox;
          display: flex;
          margin-right: 16px; }
        .sidebar .menu-link .menu-item-container .menu-item .main-tab .name-container {
          color: #E0E0E0;
          text-transform: uppercase;
          letter-spacing: 2px; }

.billing-address-form.form-submitting .form-fields-container {
  opacity: 0.5; }

.billing-address-form .form-fields-container {
  opacity: 1;
  -webkit-transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .billing-address-form .form-fields-container .form-fields-title {
    text-align: left;
    font-size: 13px;
    color: #757575;
    font-family: 'Px Grotesk Bold';
    padding-bottom: 10px; }
  .billing-address-form .form-fields-container .form-fields {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .billing-address-form .form-fields-container .form-fields .form-input-field {
      margin-bottom: 10px; }
  .billing-address-form .form-fields-container.name-fields .form-input-field {
    width: 48%; }
  .billing-address-form .form-fields-container.billing-address-fields {
    margin: 50px 0px; }
    .billing-address-form .form-fields-container.billing-address-fields .field-country, .billing-address-form .form-fields-container.billing-address-fields .field-state, .billing-address-form .form-fields-container.billing-address-fields .field-city, .billing-address-form .form-fields-container.billing-address-fields .field-zipcode {
      text-align: left;
      width: 48%; }

.billing-address-form .error-container {
  margin-top: 20px; }
  .billing-address-form .error-container .error {
    font-size: 12px;
    color: #f44336; }
